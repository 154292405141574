<template>
    <v-container>
        <h1 class=" font-weight-light mt-5 pt-5">Hotel Information</h1>
        <div class="row">
            <div class="col-md-8">
                <v-card class="relative">
                    <v-card-title class="absolute">
                        <span class="title font-weight-light">Edit Informations</span>
                    </v-card-title>
                    <form class="px-2">
                        <v-row>
                            <v-text-field
                                    v-model="name"
                                    :error-messages="nameErrors"
                                    label="Name"
                                    required
                                    @input="$v.name.$touch()"
                                    @blur="$v.name.$touch()"
                                    class="col-12"
                            ></v-text-field> 
                            <v-text-field
                                    v-model="email"
                                    :error-messages="emailErrors"
                                    label="E-mail"
                                    required
                                    @input="$v.email.$touch()"
                                    @blur="$v.email.$touch()"
                                    class="col-12"
                            ></v-text-field>
                            <v-text-field
                                    v-model="phone"
                                    :error-messages="phoneErrors"
                                    label="Phone"
                                    required
                                    @input="$v.phone.$touch()"
                                    @blur="$v.phone.$touch()"
                                    class="col-12"
                            ></v-text-field>
                            <v-text-field
                                    v-model="address"
                                    :error-messages="addressErrors"
                                    label="Address"
                                    required
                                    @input="$v.address.$touch()"
                                    @blur="$v.address.$touch()"
                                    class="col-12"
                            ></v-text-field>
                            <v-text-field
                                    v-model="address"
                                    :error-messages="addressErrors"
                                    label="Website"
                                    required
                                    @input="$v.address.$touch()"
                                    @blur="$v.address.$touch()"
                                    class="col-12"
                            ></v-text-field>
                            <!-- <v-file-input small-chips multiple label="Register Document"
                                           show-size class="col-12"
                             ></v-file-input>
                             <v-file-input small-chips multiple label="Register Document"
                                           show-size class="col-12"
                             ></v-file-input>
                             <v-file-input small-chips multiple label="Vat Document"
                                           show-size class="col-12"
                             ></v-file-input>-->
                            <div class="col-12">
                                <v-btn dark flat depressed class="mr-4 blue darken-1" @click="submit">Update</v-btn>
                            </div>
                        </v-row>
                    </form>
                </v-card>
            </div>
            <div class="col-md-4">
                <v-card
                        class="mx-auto"
                        outlined
                >
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="overline mb-4">Profile</div>
                            <v-list-item-title class="headline mb-1">Hotel 11 Daiamonds</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-img
                            src="https://cdn.vuetifyjs.com/images/cards/mountain.jpg"
                            height="194"
                    ></v-img>
                    <v-file-input
                            accept="image/png, image/jpeg, image/bmp"
                            placeholder="Pick an avatar"
                            prepend-icon="mdi-camera"
                            label="Avatar"
                            class="pl-2"
                    ></v-file-input>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <v-list-item-subtitle>Kapan-3, Kathmandu, Nepal</v-list-item-subtitle>
                            <v-list-item-subtitle class="pt-1">9803640949, 9803640949</v-list-item-subtitle>
                            <v-list-item-subtitle class="pt-1">11diamond@gmail.com</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
                <v-expansion-panels>
                    <v-expansion-panel
                    >
                        <v-expansion-panel-header color=" dark-blue">Documents</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-card class="py-5 " flat>
                                <v-row>
                                    <v-col cols="4">
                                        <v-dialog
                                                v-model="dialog"
                                                width="500"

                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-img class="pb-2"
                                                       src="https://cdn.vuetifyjs.com/images/cards/mountain.jpg"
                                                       height="50"
                                                       v-on="on"
                                                ></v-img>
                                            </template>

                                            <v-card>
                                                <v-card-title
                                                        class="headline grey lighten-2"
                                                        primary-title
                                                >
                                                    Document Title
                                                </v-card-title>

                                                <v-card-text>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.
                                                </v-card-text>

                                                <v-divider></v-divider>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-dialog
                                                v-model="dialog"
                                                width="500"

                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-img class="pb-2"
                                                       src="https://cdn.vuetifyjs.com/images/cards/mountain.jpg"
                                                       height="50"
                                                       v-on="on"
                                                ></v-img>
                                            </template>

                                            <v-card>
                                                <v-card-title
                                                        class="headline grey lighten-2"
                                                        primary-title
                                                >
                                                    Document Title
                                                </v-card-title>

                                                <v-card-text>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.
                                                </v-card-text>

                                                <v-divider></v-divider>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-dialog
                                                v-model="dialog"
                                                width="500"

                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-img class="pb-2"
                                                       src="https://cdn.vuetifyjs.com/images/cards/mountain.jpg"
                                                       height="50"
                                                       v-on="on"
                                                ></v-img>
                                            </template>

                                            <v-card>
                                                <v-card-title
                                                        class="headline grey lighten-2"
                                                        primary-title
                                                >
                                                    Document Title
                                                </v-card-title>

                                                <v-card-text>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                                                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                                    culpa qui officia deserunt mollit anim id est laborum.
                                                </v-card-text>

                                                <v-divider></v-divider>
                                            </v-card>
                                        </v-dialog>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </div>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        name: "HotelDetails",
        props: ["success"],
        watch: {
            created: {
                deep: true
            },
        },
        computed: {},
        data() {
            return {};
        },
        methods: {
            async formData() {
                let permissionData = JSON.parse(localStorage.getItem("tokenData"));
                if (permissionData.role == "Ministry") {
                    this.provShow = true;
                    this.divShow = true;
                    const provinceRequest = await axios.get("Province/DDLProvinceList");
                    this.province = provinceRequest.data;
                } else if (permissionData.role == "Province") {
                    this.divShow = true;
                    this.editedUser.provinceId = permissionData.ProvinceID;
                } else if (permissionData.role == "Division") {
                    this.editedUser.provinceId = permissionData.ProvinceID;
                    this.editedUser.divisionId = permissionData.DivisionID;
                }
            },

        }
    };
</script>

<style lang="scss" scoped>

</style>
